import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextV2 } from '@insights-ltd/design-library/components';
import JobForm from 'components/JobForm';
import { CreateJobType } from 'types/job';
import { useCreateJob } from 'api/hooks';
import Navigation from 'components/Navigation';

const CreateJob = () => {
  const navigate = useNavigate();
  const { mutate, status, reset } = useCreateJob();

  const onCreateJob = (job: CreateJobType) =>
    mutate(job, {
      onSuccess: (createdJob) => navigate(`/jobs/${createdJob.id}`),
    });

  const onCancel = () => navigate('/jobs');

  return (
    <>
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          '> * + *': { marginTop: theme.spacing(3) },
        })}
      >
        <Navigation
          breadcrumbs={[
            { route: '/jobs', children: 'jobs' },
            { route: '', children: 'create job' },
          ]}
        />
        <Grid container>
          <Grid item xs={12} md={8}>
            <TextV2 variant="h2">New Job</TextV2>
            <TextV2 color="textSecondary">
              Create a new job to add strings
            </TextV2>
            <Box sx={(theme) => ({ mb: theme.spacing(4) })} />
            <Card elevation={0}>
              <CardContent>
                <JobForm onSubmit={onCreateJob}>
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={(theme) => ({ marginTop: theme.spacing(3) })}
                  >
                    <Button onClick={onCancel} variant="outlined">
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Create Job
                    </Button>
                  </Grid>
                </JobForm>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={status === 'error'}
        autoHideDuration={6000}
        onClose={reset}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          Failed to create a job. Please try again later.
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateJob;

import React from 'react';
import { SvgComponentProps } from './types';

const SvgCheckboxPartial = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect
      width={23}
      height={23}
      x={0.5}
      y={0.619}
      fill="#0E567B"
      stroke="#0E567B"
      rx={3.5}
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="bevel"
      strokeWidth={3}
      d="M5.5 12.619h13"
    />
  </svg>
);

export default SvgCheckboxPartial;

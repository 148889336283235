import React from 'react';
import Grid from '@mui/material/Grid';
import { TextV2 } from '@insights-ltd/design-library/components';
import { IJob } from 'types/job';
import { EnumJobStatus } from 'variables';
import { JobStrings } from 'components/Jobs';
import Navigation from 'components/Navigation';
import { StringsResponseList } from 'types/api';
import { useJobContext } from 'pages/Job/hooks/jobContext';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import JobDetails from './JobDetails';

type Props = {
  job: IJob;
  translations?: StringsResponseList;
};

const LoadedJob = ({ job, translations }: Props) => {
  const areStringsEditable =
    job.status === EnumJobStatus.DRAFT ||
    job.status === EnumJobStatus.AWAITING_QUOTE;

  const isExportEnabled =
    job.status !== EnumJobStatus.CANCELLED &&
    job.status !== EnumJobStatus.PUBLISHED &&
    job.translations?.length !== 0;

  const {
    importStringsQuery: { mutate: importStrings },
    publishJobQuery: { mutate: publishJob },
    stringSelection: {
      removingStrings,
      selectedStrings,
      setSelectedStrings,
      startRemovingStrings,
      stopRemovingStrings,
    },
    jobStatusDialog: { openDialog: openJobStatusDialog },
  } = useJobContext();

  const onImport = (file: File) => importStrings({ jobId: job.id, file });

  const onPublish = () => publishJob({ jobId: job.id });

  const translationEntries = Object.entries(translations || {});
  return (
    <Grid
      container
      spacing={1}
      sx={(theme) => ({
        marginTop: theme.spacing(spacingSizeMap.M),
      })}
    >
      <Grid item xs={12}>
        <Navigation
          breadcrumbs={[
            { route: '/jobs', children: 'jobs' },
            { route: '', children: job.id },
          ]}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          marginTop: theme.spacing(spacingSizeMap.XS),
          marginBottom: theme.spacing(spacingSizeMap.S),
        })}
      >
        <TextV2 variant="h2">
          Job ID{' '}
          <TextV2 variant="h2" component="span" color="textSecondary">
            {job.id}
          </TextV2>
        </TextV2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <JobDetails
              job={job}
              enableExport={isExportEnabled}
              onImport={onImport}
              publishJob={onPublish}
              openDialog={openJobStatusDialog}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <JobStrings
              job={job}
              strings={translationEntries}
              isJobEditable={areStringsEditable}
              removingStrings={removingStrings}
              selectedStrings={selectedStrings}
              setSelectedStrings={setSelectedStrings}
              startRemovingStrings={startRemovingStrings}
              stopRemovingStrings={stopRemovingStrings}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadedJob;

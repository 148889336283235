import React from 'react';
import { SvgComponentProps } from './types';

const SvgCheckboxUnchecked = ({ title, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <rect
      width={23}
      height={23}
      x={0.5}
      y={1.268}
      fill="#fff"
      stroke="#1673A3"
      rx={3.5}
    />
  </svg>
);

export default SvgCheckboxUnchecked;

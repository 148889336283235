import React, { ReactNode } from 'react';
import { AppBar, Container, Grid } from '@mui/material';
import { SvgInsightsLogo } from '@insights-ltd/design-library/components';

type Props = {
  children: ReactNode;
};

const HeaderLayout = ({ children }: Props) => {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.info.dark}`,
      })}
    >
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          sx={(theme) => ({
            padding: `${theme.spacing(2)} 0`,
            '& > svg': {
              fill: theme.palette.blue.main,
              width: '8rem',
              height: '2.125rem',
            },
          })}
        >
          <SvgInsightsLogo />
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default HeaderLayout;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextV2 } from '@insights-ltd/design-library/components';
import { CreateJobType, IJob } from 'types/job';
import { useEditJob, useGetJob } from 'api/hooks';
import Navigation from 'components/Navigation';
import JobForm from 'components/JobForm';
import { JobRouteParams } from 'types';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';

const responseToForm = (job: IJob): CreateJobType => job as CreateJobType;

const EditJob = () => {
  const navigate = useNavigate();
  const { jobId } = useParams<JobRouteParams>();
  const { status: getJobStatus, data } = useGetJob(jobId || '');
  const { mutate: editJob, status: editJobStatus, reset } = useEditJob();

  if (getJobStatus === 'pending') {
    return <FullScreenSpinner message="Loading..." />;
  }
  if (getJobStatus === 'error') {
    return (
      <FullScreenError message="There was an error finding the job, please try refresh the page." />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const job = data!;
  const jobForm = responseToForm(job);

  const onSaveJob = (updatedJob: CreateJobType) =>
    editJob(
      { ...job, ...updatedJob },
      { onSuccess: () => navigate(`/jobs/${job.id}`) },
    );

  const onCancel = () => navigate(`/jobs/${job.id}`);

  return (
    <>
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          '> * + *': { marginTop: theme.spacing(3) },
        })}
      >
        <Navigation
          breadcrumbs={[
            { route: '/jobs', children: 'jobs' },
            { route: `/jobs/${job.id}`, children: job.id },
            { route: '', children: 'Edit Job' },
          ]}
        />
        <Grid container>
          <Grid item xs={12} md={8}>
            <TextV2 variant="h2">{job.title}</TextV2>
            <Box sx={(theme) => ({ mb: theme.spacing(4) })} />
            <Card elevation={0}>
              <CardContent>
                <JobForm edit onSubmit={onSaveJob} defaultValues={jobForm}>
                  <Grid
                    container
                    justifyContent="space-between"
                    sx={(theme) => ({ marginTop: theme.spacing(3) })}
                  >
                    <Button onClick={onCancel} variant="outlined">
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Save Job
                    </Button>
                  </Grid>
                </JobForm>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={editJobStatus === 'error'}
        autoHideDuration={6000}
        onClose={reset}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert elevation={6} variant="filled" onClose={reset} severity="error">
          Failed to save job. Please try again later.
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditJob;
